/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import AdviceView from '../views/AdviceView/AdviceView';
import vectorPostOperation from '../images/vectorPostOperation.svg';
import SEO from '../components/SEO';

const contactUsText = (
  <p>
    Vous avez subi une intervention chirurgicale dentaire avec l’un de nos
    spécialistes des cliniques Maxillo Québec, vous avez des interrogations ou
    inquiétudes suite à votre opération, vous pouvez rejoindre l’un membres de
    notre équipe afin de vous guider et de vous rassurer.
  </p>
);

const AdvicePostOperationTemplate = ({ data }) => {
  const { sanityAdvicePostOperation: advice } = data;

  return (
    <>
      <SEO
        title={advice.seo.seo_title}
        description={advice.seo.meta_description}
      />

      <AdviceView
        title={advice.name}
        preTitle='Conseils après mon opération'
        rawBody={advice._rawBody}
        iconSrc={vectorPostOperation}
        renderContactUsText={contactUsText}
      />
    </>
  );
};

AdvicePostOperationTemplate.propTypes = {
  data: PropTypes.shape({
    sanityAdvicePostOperation: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default AdvicePostOperationTemplate;

export const query = graphql`
  query($id: String!) {
    sanityAdvicePostOperation(id: { eq: $id }) {
      name
      _rawBody(resolveReferences: { maxDepth: 10 })
      seo {
        meta_description
        seo_title
      }
      slug {
        current
      }
    }
  }
`;
